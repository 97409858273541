import { aiContentCreatorReducerTypes } from 'types';

export enum IAISugesstionTabs {
  CAPTIONS_AND_HASHTAGS = 'captions_and_hashtags',
  IMAGES = 'Images'
}

export enum IAIResultsTab {
  CONTENTS = 'content',
  IMAGES = 'image',
  HASHTAGS = 'hashtags',
  MY_IMAGES = 'my_images'
}

export enum ICSSubscriptionPlans {
  FREE = 'FREE',
  TIER2 = 'TIER2',
  TIER2POINT5 = 'TIER2.5',
  CS_PREMIUM = 'CS_PREMIUM'
}

export enum IAISubscriptionPlansDurationVal {
  MONTH = 'month',
  SIX_MONTH = 'six_month',
  YEAR = 'year',
  SIX_MONTH_DISCOUNT = 'six_month_discount'
}

export const AI_RESULTS_CAPTIONS_TAB = [
  {
    label: 'Captions',
    value: IAIResultsTab.CONTENTS
  },
  {
    label: 'Hashtags',
    value: IAIResultsTab.HASHTAGS
  }
];

export const AI_RESULTS_IMAGES_TAB = [
  {
    label: 'AI Image',
    value: IAIResultsTab.IMAGES
  },
  {
    label: 'My Images',
    value: IAIResultsTab.MY_IMAGES
  }
];

export const AI_SUGESSTIONS_TAB = [
  {
    label: 'Captions & Hashtags',
    value: IAISugesstionTabs.CAPTIONS_AND_HASHTAGS
  },
  {
    label: 'AI Image',
    value: IAISugesstionTabs.IMAGES
  }
];

export const SUBSCRIPTION_PLANS = {
  FBBC_MONTH: 'FBBC_Month',
  FBBC_SIX_MONTH: 'FBBC_Six_Month',
  FBBC_YEAR: 'FBBC_Year',
  JBF_SIX_MONTH_REGULAR: 'JBF_Six_Month-Regular',
  JBF_SIX_MONTH_DISCOUNT: 'JBF_Six_Month-Discount',
  FS_MONTH: 'FS_Month'
};

export const AI_SUBSCRIPTION_PLAN_DURATION_LABELS = {
  FREE_TRIAL: 'Free trial',
  MONTH: 'Monthly',
  SIX_MONTH: 'Half Yearly',
  YEAR: 'Yearly'
};

export const AI_SUBSCRIPTION_TAB: aiContentCreatorReducerTypes.IAISubscriptionTab[] = [
  {
    label: 'Monthly',
    value: 'month',
    price: 29,
    trialPrice: 29,
    class: 'raip-item raip-monthly',
    text: 'Lorem ipsum dolor sit amet,',
    billing_period: 'mo'
  },
  {
    label: 'Yearly',
    value: 'year',
    price: 288,
    trialPrice: 24,
    class: 'raip-item raip-yearly',
    text: 'Lorem ipsum dolor sit amet,',
    billing_period: 'year'
  }
];

export const CONTENT_SUPPLIERS_SUBSCRIPTION_PLANS_TAB: aiContentCreatorReducerTypes.IAISubscriptionTab[] = [
  {
    label: 'Tier 2',
    value: ICSSubscriptionPlans.TIER2,
    price: 10,
    trialPrice: 0,
    features: [
      'Branded content auto published to your social media accounts',
      'Access to the platform to manage your social media & reviews - post your own content',
      'Ability to edit or delete corporate - created content',
      'Reporting',
      'Revv'
    ],
    franchisor_subscription_type_id: 2
  },
  {
    label: 'Tier 2.5',
    value: ICSSubscriptionPlans.TIER2POINT5,
    price: 25,
    trialPrice: 0,
    features: ['Rallio AI'],
    franchisor_subscription_type_id: 3
  }
];

export const CS_LOCATIONS_SUBSCRIPTION_PLAN = {
  // label: 'AI Plan',
  // value: ICSSubscriptionPlans.TIER2,
  price: 9.95,
  // trialPrice: 0,
  features: [
    'AI-Written Social Media Posts',
    'AI Image Generation',
    'Review Management System with AI-Written Review Responses',
    'Engagement System that Allows You the Ability to Respond to Every Comment, Direct Message, and Mention Across All Your Social Media Platforms'
  ],
  franchisor_subscription_type_id: 2
};

export const AI_SUBSCRIPTION_TABLE_HEADERS = [
  {
    title: '',
    value: '',
    className: 'ad-status'
  },
  {
    title: 'Subscription Type',
    value: 'subscritpionPeriod',
    className: ''
    // price: 29,
    // class: 'raip-item raip-monthly',
  },
  {
    title: 'Plan',
    value: 'plan',
    className: ''
    // price: 288,
    // class: 'raip-item raip-yearly',
    // text: 'Lorem ipsum dolor sit amet,'
  },
  {
    title: 'Purchase Date',
    value: 'purchaseDate',
    className: 'sort-active'
    // price: 29,
    // class: 'raip-item raip-monthly',
  },
  {
    title: 'Renewal Status',
    value: 'dueDate',
    className: ''
    // price: 29,
    // class: 'raip-item raip-monthly',
  },
  {
    title: '# Locations',
    value: 'locations',
    className: ''
    // price: 29,
    // class: 'raip-item raip-monthly',
  },
  {
    title: 'Amount',
    value: 'amount',
    className: ''
    // class: 'raip-item raip-monthly',
  },
  {
    title: '',
    value: 'cancel',
    className: ''
    // class: 'raip-item raip-monthly',
  }
];

export const AI_COACHMARK = [
  {
    title: 'Rallio AI - Captions Generator',
    value: 'caption',
    text: `Rallio AI is ready to carry to go to work for you! Not sure the best caption? Want some emoji and hashtag suggestions? Want a new way to say something? Rallio AI has it all and will give you the captions to light up your posts! Get ready to have some fun and be amazed ! Give it a try!`,
    videoUrl: 'https://res.cloudinary.com/ralliohq/video/upload/v1665738898/AI%20Coach%20Mark/ai_create_caption.mp4'
  },
  {
    title: 'Rallio AI - Post Generator',
    value: 'post',
    text: `Rallio AI is ready to go to work for you! Like one of your posts and would love to have another similar one? Simply click on the Rallio AI logo and in seconds it will produce a new similar post for you to approve! Get ready to be amazed! Give it a try!`,
    videoUrl: 'https://res.cloudinary.com/ralliohq/video/upload/v1665738893/AI%20Coach%20Mark/ai_create_post.mp4'
  },
  {
    title: 'Rallio AI - Review Response Generator',
    value: 'review',
    text: `Rallio AI is ready to help you respond to these reviews! Not sure what to say? Want to speed up the process? Rallio AI is here to help provide great responses for you. Get ready for some help! Give it a try!`,
    videoUrl: 'https://res.cloudinary.com/ralliohq/video/upload/v1665738895/AI%20Coach%20Mark/ai_review_responses.mp4'
  }
];

export const AI_IMAGE_NO_RESULTS_FOUND = 'No similar image was found to recommend. Please upload more images.';

export const AI_LOADING_CONTEXT_MSG = `I'm thinking of another caption, considering emojis, writing \nsome hashtags, and reviewing the images in the database for \na good fit… almost done!`;

export const AI_LOADING_CONTEXT_MSG_FOR_MEDIA = `I'm thinking of a caption, considering emojis, and writing some hashtags.. almost done!`;

export const AI_LOADING_IMAGE_GENERATION_CONTEXT_MSG = `I've almost finished creating the image based on your description...I hope you like it!`;

export const AI_IMAGE_VALIDATION_MSG = `I'm validating your description to generate the image... almost done!`;

const BASIC_PHONE_REGEX = /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g;
const BASIC_EMAIL_REGEX = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;

const VALID_PHONE_NUMBER_PLACEHOLDER_REGEX = {
  BUSINESS_MOBILE_NUMBER: /\[(insert|enter|update|add)?( |_|-)?(business)?( |_|-)?(phone|mobile|contact)( |_|-)(number)\]/gi
};

const VALID_EMAIL_PLACEHOLDER_REGEX = {
  BUSINESS_EMAIL: /\[(insert|enter|update|add)?( |_|-)?(business)?( |_|-)?(email)( |_|-)?(id|address)?\]/gi
};

export const AI_PHONE_NUMBER_VALIDATOR = [BASIC_PHONE_REGEX, VALID_PHONE_NUMBER_PLACEHOLDER_REGEX.BUSINESS_MOBILE_NUMBER];
export const AI_EMAIL_VALIDATOR = [BASIC_EMAIL_REGEX, VALID_EMAIL_PLACEHOLDER_REGEX.BUSINESS_EMAIL];

export const VALID_BRAND_CATEGORY_REGEX = /&nbsp;/g;

export const CS_LOCATION_AI_CAPTION_SUBSCRIPTION_MSG = 'You have reached your limit of 15 free AI uses. For continuous and unlimited access to AI, upgrade now. ';
export const CS_LOCATION_AI_IMAGE_SUBSCRIPTION_MSG = 'You have completed your 5 free AI image generation. Upgrade now to unlock unrestricted AI caption generation and more!';
export const CS_LOCATION_COMMUNITY_SUBSCRIPTION_MSG =
  'Premium membership provides unlimited AI for posts and image creation, a review management system with AI responses, and an engagement system for all social platform interactions.';
export const CS_LOCATION_REPUTATION_SUBSCRIPTION_MSG =
  'Premium membership provides unlimited AI for posts and image creation, a review management system with AI responses, and an engagement system for all social platform interactions.';

export const CS_LOCATION_SUBSCRIPTION_TYPES = [
  { type: 'ai_caption', message: CS_LOCATION_AI_CAPTION_SUBSCRIPTION_MSG },
  { type: 'ai_image', message: CS_LOCATION_AI_IMAGE_SUBSCRIPTION_MSG },
  { type: 'ai_community', message: CS_LOCATION_COMMUNITY_SUBSCRIPTION_MSG },
  { type: 'ai_reputation', message: CS_LOCATION_REPUTATION_SUBSCRIPTION_MSG }
];

export const FBBC_BRAND_SUBSCRIPTION_TAB: aiContentCreatorReducerTypes.IAISubscriptionTab[] = [
  {
    label: AI_SUBSCRIPTION_PLAN_DURATION_LABELS.MONTH,
    value: IAISubscriptionPlansDurationVal.MONTH,
    price: 25,
    trialPrice: 0,
    class: 'raip-item raip-monthly rai-m',
    text: 'Lorem ipsum dolor sit amet,',
    billing_period: 'month',
    type: 'FBBC',
    franchisor_subscription_type_id: 9,
    subscription_plan: SUBSCRIPTION_PLANS.FBBC_MONTH,
    programDuration: IAISubscriptionPlansDurationVal.MONTH
  },
  {
    label: AI_SUBSCRIPTION_PLAN_DURATION_LABELS.SIX_MONTH,
    value: IAISubscriptionPlansDurationVal.SIX_MONTH,
    price: 119,
    trialPrice: 0,
    class: 'raip-item raip-yearly rai-hy',
    text: 'Lorem ipsum dolor sit amet,',
    billing_period: 'half year',
    type: 'FBBC',
    franchisor_subscription_type_id: 10,
    subscription_plan: SUBSCRIPTION_PLANS.FBBC_SIX_MONTH,
    programDuration: IAISubscriptionPlansDurationVal.SIX_MONTH
  },
  {
    label: AI_SUBSCRIPTION_PLAN_DURATION_LABELS.YEAR,
    value: IAISubscriptionPlansDurationVal.YEAR,
    price: 225,
    trialPrice: 0,
    class: 'raip-item raip-yearly rai-y',
    text: 'Lorem ipsum dolor sit amet,',
    billing_period: 'year',
    type: 'FBBC',
    franchisor_subscription_type_id: 11,
    subscription_plan: SUBSCRIPTION_PLANS.FBBC_YEAR,
    programDuration: IAISubscriptionPlansDurationVal.YEAR
  }
];

export const JBF_BRAND_SIX_MONTH_REGULAR_PLAN: aiContentCreatorReducerTypes.IAISubscriptionTab = {
  label: AI_SUBSCRIPTION_PLAN_DURATION_LABELS.SIX_MONTH,
  value: IAISubscriptionPlansDurationVal.SIX_MONTH,
  price: 149.99,
  trialPrice: 0,
  class: 'raip-item raip-yearly rai-hy',
  text: 'Lorem ipsum dolor sit amet,',
  billing_period: 'half year',
  type: 'JBF',
  franchisor_subscription_type_id: 12,
  subscription_plan: SUBSCRIPTION_PLANS.JBF_SIX_MONTH_REGULAR,
  programDuration: IAISubscriptionPlansDurationVal.SIX_MONTH
};

export const JBF_BRAND_SIX_MONTH_DISCOUNT_PLAN: aiContentCreatorReducerTypes.IAISubscriptionTab = {
  label: AI_SUBSCRIPTION_PLAN_DURATION_LABELS.SIX_MONTH,
  value: IAISubscriptionPlansDurationVal.SIX_MONTH,
  price: 119.99,
  trialPrice: 0,
  class: 'raip-item raip-yearly rai-hy',
  text: 'Lorem ipsum dolor sit amet,',
  billing_period: 'half year',
  type: 'JBF',
  franchisor_subscription_type_id: 13,
  subscription_plan: SUBSCRIPTION_PLANS.JBF_SIX_MONTH_DISCOUNT,
  programDuration: IAISubscriptionPlansDurationVal.SIX_MONTH_DISCOUNT
};

export const FS_BRAND_SUBSCRIPTION_TAB: aiContentCreatorReducerTypes.IAISubscriptionTab[] = [
  {
    label: AI_SUBSCRIPTION_PLAN_DURATION_LABELS.MONTH,
    value: IAISubscriptionPlansDurationVal.MONTH,
    price: 25,
    trialPrice: 0,
    class: 'raip-item raip-monthly rai-m',
    text: 'Lorem ipsum dolor sit amet,',
    billing_period: 'month',
    type: 'FS',
    franchisor_subscription_type_id: 16,
    subscription_plan: SUBSCRIPTION_PLANS.FS_MONTH,
    programDuration: IAISubscriptionPlansDurationVal.MONTH
  }
];

export const AI_SUBSCRIPTION_PLAN_TYPES = {
  TRIAL_PERIOD: 'trialPeriod',
  FBBC_BRAND: 'FBBC',
  JBF_BRAND: 'JBF',
  FS_BRAND: 'FS'
};

export const AI_SUBSCRIPTION_PLAN_NAMES = {
  FREE_TIER: 'Tier 1 - Free',
  TIER_2: 'Tier 2',
  TIER2POINT5: 'Tier 2.5',
  RC_FREE: 'RC_Free',
  RC_PREMIUM: 'RC_Premium',
  FS_MONTH: 'FS_Month'
};
